import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as RefundIcon } from 'src/assets/refund.svg'
import { useSelector } from 'react-redux'
import s from './styles.module.scss'
import { getSelectedLanguage } from '../../state/general/selectors'
import translations from '../../translations/manager'
import Spinner from '../shared/Spinner'
import { useRestApi } from '../../hooks/useRestApi'

export default function RefundReceipt() {
  const params = useParams()

  const [{ data, loading, error }, { sendRequest }] = useRestApi('/search')

  useEffect(() => {
    if (params?.paymentId) {
      sendRequest({
        method: 'POST',
        data: {
          paymentId: params.paymentId
        }
      })
    }
  }, [])

  const selectedLanguage = useSelector(getSelectedLanguage)
  const t = translations[selectedLanguage]

  const currentPayment = data?.[0]

  const refunds = currentPayment?.refunds?.data?.reverse() || []
  const refundReceipt = refunds?.[0]

  const parsePaymentMethod = method => {
    switch (method) {
      case 'apay':
        return 'ApplePay'
      case 'gpay':
        return 'GooglePay'
      case 'gpaycard':
        return 'GooglePay Card'
      case 'privat24':
        return 'Privat24'
      case 'liqpay':
        return 'LiqPay'
      case 'cash':
        return t.cash
      case 'card':
        return t.card
      default:
        return method
    }
  }

  if (loading || error) {
    return (
      <div className={s.refundReceipt}>
        <Spinner>{t.receiptLoading}</Spinner>
      </div>
    )
  }

  if (!refundReceipt) {
    return (
      <div className={s.refundReceiptEmpty}>
        <p>{t.receiptNotFound}</p>
      </div>
    )
  }

  const paymentId = refundReceipt?.cb?.transaction_id || refundReceipt?.res?.payment_id
  return (
    <div className={s.refundReceipt}>
      <div className={s.refundReceiptHeader}>
        <h4 className={s.refundReceiptTitle}>
          <RefundIcon width={20} height={20} />
          {t.receipt}
        </h4>
        <strong className={s.refundReceiptNumber}>ID:&nbsp;{paymentId}</strong>
      </div>
      <div className={s.refundReceiptBody}>
        <RefundSection title={t.paymentData}>
          <RefundSectionRow title={t.paymentId} value={paymentId} />
          <RefundSectionRow
            title={t.created}
            value={new Date(refundReceipt?.cb?.create_date || currentPayment?.paid)?.toLocaleString('uk-UA')}
          />
          {!refundReceipt?.cb?.description || <RefundSectionRow title={t.paymentDescription} value={refundReceipt.cb.description} />}
          <RefundSectionRow
            title={t.paymentStatus}
            value={
              refundReceipt?.cb?.status === 'reversed' ? (
                <span className={s.refundLabel}>{t.paymentRefund}</span>
              ) : (
                refundReceipt?.cb?.status || t.pendingBankResponse
              )
            }
          />
          <div className={s.dividerMobile} />
          <RefundSectionRow
            title={t.paymentAmount}
            value={`${refundReceipt?.cb?.amount || currentPayment?.amountPaid / 100} ${refundReceipt?.cb?.currency || currentPayment?.currency}`}
          />
        </RefundSection>
        {refundReceipt?.cb?.sender_card_mask2 && refundReceipt?.cb?.paytype ? (
          <RefundSection title={t.client}>
            <RefundSectionRow title={t.paymentCard} value={refundReceipt.cb.sender_card_mask2} />
            <RefundSectionRow title={t.paymentMethod} value={parsePaymentMethod(refundReceipt.cb.paytype)} />
          </RefundSection>
        ) : <RefundSection />}
        <hr className={s.hrMobile} />
        <RefundSection title={t.paymentRefund}>
          {refunds.map((refund, i) => (
            <div key={`refund-${i}`} className={s.divider}>
              <RefundSectionRow
                title={t.created}
                value={new Date(refund.cb?.refund_date_last || currentPayment?.status?.refundStarted)?.toLocaleString('uk-UA')}
              />
              <RefundSectionRow title={t.paymentAmount} value={`${refund.cb?.refund_amount || refund.req?.amount} ${refund.cb?.currency || currentPayment?.currency}`} />
              <RefundSectionRow title={t.refundReason} value={refund.refundReason} />
              <RefundSectionRow
                title={t.paymentStatus}
                value={refund.cb?.status === 'reversed' ? <span className={s.successLabel}>{t.paymentSuccess}</span> : t.pendingBankResponse}
              />
            </div>
          ))}
        </RefundSection>
        <p className={s.hint}>{t.refundExtraInfo}</p>
      </div>
    </div>
  )
}

const RefundSection = ({ title, children }) => (
  <div className={s.refundReceiptSection}>
    <h5 className={s.refundReceiptSectionTitle}>{title}</h5>
    {children}
  </div>
)

const RefundSectionRow = ({ title, value }) => (
  <div className={s.refundReceiptSectionRow}>
    <span>{title}</span>
    <strong>{value}</strong>
  </div>
)
