import { SERVICES_TYPE } from 'src/globals/enumerations'

const translationsManager = {
  ru: {
    findPayment: 'Поиск платежа',
    stopList: 'Стоплист',
    dishes: 'Блюда',
    allDishes: 'Все блюда',
    modifiers: 'Модификаторы',
    statistics: 'Статистика',
    onOffExplain: 'Приложение включается и выключается автоматически, по графику работы заведения',
    off: 'Откл',
    on: 'Вкл',
    venueOff: 'Заведение отключено!',
    slOnly: 'СЛ только',
    allCategory: 'Все категории',
    dish: 'блюд',
    mods: 'моди',
    ingredients: 'Ингридиенты',
    permanent: 'Перманентно',
    addToStopList: 'Добавить в СЛ',
    removeFromStopList: 'Убрать из СЛ',
    orders: 'Заказов',
    revenue: 'Выручка',
    visitors: 'Посетителей',
    newVisitors: 'Новых посетителей',
    loading: 'Загрузка...',
    closingShift: 'Закрытие смены',
    revenueVenue: 'Выручка ресторана',
    totalRevenue: 'Общая выручка',
    send: 'Отправить',
    byPhone: 'По № тел',
    bySum: 'По сумме',
    byTable: 'По № стола',
    byOrderNumber: 'По № заказа',
    close: 'Закрыть',
    transferOrderDone: 'Перенос заказа выполнен',
    order: 'Заказ',
    successTransferOrderFrom: 'успешно перенесен из',
    in: 'в',
    return: 'Вернуть',
    reasonForRefund: 'Причина возврата оплатежа (обязательно)',
    back: 'Назад',
    forOneDay: 'За день',
    forThreeDay: 'За три дня',
    forLastWeek: 'За последнюю неделю',
    forLastMonth: 'За месяц (30 дней)',
    explainForTransferOrder: '№ заказа или не менее 5 цифр для тел',
    refundPayment: 'Возврат средств',
    refundReceipt: 'Квитанция по возврату',
    transferPayment: 'Перенос платежа',
    orderWithPayment: 'Оплаченные заказы',
    orderWithoutPayment: 'Неоплаченные заказы',
    withoutPhone: 'Без телефона',
    withoutName: 'Без имени',
    today: 'Сегодня',
    novaPoshtaDeliveryAddress: (address, dept, phone, name) =>
      `ДОСТАВКА Новой Почтой - адрес: ${address}, отделение НП: ${dept}, тел: ${phone}, ФИО: ${name}`,
    deliveryAddress: (address, phone, name) =>
      `ДОСТАВКА - адрес: ${address}, тел: ${phone}${name ? `, имя: ${name}` : ''}`,
    total: 'Итого',
    totalRefund: 'Итого к возврату',
    transfer: 'Перенести',
    from: 'Из',
    to: 'В',
    refundPaymentButton: 'Вернуть средства',
    confirmationRefundFirstPart: 'По заказу',
    confirmationRefundSecondPart: 'успешно проведен возврат на указанную сумму',
    errorTransferOrder: 'Ошибка переноса заказа',
    selectVenueToTransferOrderHint: 'Выберите заведение в которое хотите перенести заказ',
    services: 'Сервисы',
    [SERVICES_TYPE.HERE]: 'В заведении',
    [SERVICES_TYPE.TAKEAWAY]: 'C собой',
    [SERVICES_TYPE.DELIVERY_OWN]: 'Собственная доставка',
    [SERVICES_TYPE.DELIVERY_TAXI]: 'Доставка на такси',
    [SERVICES_TYPE.DELIVERY_UKLON]: 'Доставка на такси Uklon',
    [SERVICES_TYPE.DELIVERY_NOVAPOSHTA]: 'Доставка новой почтой',
    tipsStats: 'Статистика чаевых за период:',
    tipsTotal: 'Общая сумма чаевых',
    totalCount: 'Общее кол-во чаевых',
    fromDate: 'Дата начала',
    dueDate: 'Дата окончания',
    applyButton: 'Найти',
    cancel: 'Отмена',
    receipt: 'Квитанция',
    paymentData: 'Данные платежа',
    paymentId: 'Номер транзакции:',
    receiptLoading: 'Загрузка квитанции...',
    receiptNotFound: 'Квитанцию не найдено',
    paymentRecipient: 'Получатель:',
    paymentDescription: 'Назначение:',
    client: 'Клиент',
    paymentMethod: 'Способ:',
    paymentCard: 'Карта:',
    commission: 'Комисcия:',
    statuses: 'Статусы',
    created: 'Создан:',
    card: 'Картой',
    cash: 'Наличные',
    paymentStatus: 'Статус платежа:',
    paymentRefund: 'Возврат',
    pendingBankResponse: 'Ожидаем ответа от банка',
    paymentAmount: 'Сумма:',
    paymentSuccess: 'Выполнен',
    refundReason: 'Причина возврата:',
    refundExtraInfo:
      'Возврат средств за вашу покупку успешно обработан. Зачисление средств на Ваш счет может занять до 5 дней'
  },
  uk: {
    findPayment: 'Пошук платежу',
    stopList: 'Стоплист',
    dishes: 'Страви',
    allDishes: 'Усі страви',
    modifiers: 'Модифікатори',
    statistics: 'Статистика',
    onOffExplain: 'Додаток вмикається та вимикається автоматично, згідно розкладу роботи закладу',
    off: 'Вимк',
    on: 'Вкл',
    venueOff: 'Заклад вимкнено!',
    slOnly: 'СЛ тільки',
    allCategory: 'Усі категорії',
    dish: 'страв',
    mods: 'моди',
    ingredients: 'Інгредієнти',
    permanent: 'Перманентно',
    addToStopList: 'Додати до СЛ',
    removeFromStopList: 'Прибрати з СЛ',
    orders: 'Замовлень',
    revenue: 'Виручка',
    visitors: 'Відвідувачів',
    newVisitors: 'Нових відвідувачів',
    loading: 'Завантаження...',
    closingShift: 'Закриття зміни',
    revenueVenue: 'Виручка ресторану',
    totalRevenue: 'Загальна виручка',
    send: 'Відправити',
    byPhone: 'За № тел',
    bySum: 'За сумою',
    byTable: 'За № столу',
    byOrderNumber: 'За № замовлення',
    close: 'Закрити',
    transferOrderDone: 'Перенесення замовлення виконано',
    order: 'Замовлення',
    successTransferOrderFrom: 'успішно перенесений з',
    in: 'до',
    return: 'Повернути',
    reasonForRefund: "Причина возврата оплати (обов'язково)",
    back: 'Назад',
    forOneDay: 'За день',
    forThreeDay: 'За три дні',
    forLastWeek: 'За останній тиждень',
    forLastMonth: 'За місяць (30 днів)',
    explainForTransferOrder: '№ замовлення або не меньш ніж 5 цифр для тел',
    refundPayment: 'Повернення коштів',
    transferPayment: 'Перенесення платежу',
    orderWithPayment: 'Оплачені замовлення',
    orderWithoutPayment: 'Несплачені замовлення',
    withoutPhone: 'Без телефону',
    withoutName: 'Без імені',
    today: 'Сьогодня',
    novaPoshtaDeliveryAddress: (address, dept, phone, name) =>
      `ДОСТАВКА Новою Поштой - адреса: ${address}, відділення НП: ${dept}, тел: ${phone}, ПІБ: ${name}`,
    deliveryAddress: (address, phone, name) =>
      `ДОСТАВКА - адреса: ${address}, тел: ${phone}${name ? `, ім'я: ${name}` : ''}`,
    total: 'Всього',
    totalRefund: 'Всього до повернення',
    transfer: 'Перенести',
    from: 'Із',
    to: 'До',
    refundPaymentButton: 'Повернути кошти',
    confirmationRefundFirstPart: 'По замовленню',
    confirmationRefundSecondPart: 'успішо проведено повернення коштів на вказану суму',
    errorTransferOrder: 'Помилка переносу замовлення',
    selectVenueToTransferOrderHint: 'Оберіть заклад у який потрібно перенести замовлення',
    services: 'Сервіси',
    [SERVICES_TYPE.HERE]: 'У закладі',
    [SERVICES_TYPE.TAKEAWAY]: 'З собою',
    [SERVICES_TYPE.DELIVERY_OWN]: 'Власна доставка',
    [SERVICES_TYPE.DELIVERY_TAXI]: 'Доставка на таксі',
    [SERVICES_TYPE.DELIVERY_UKLON]: 'Доставка на таксі Uklon',
    [SERVICES_TYPE.DELIVERY_NOVAPOSHTA]: 'Доставка новою поштою',
    tipsStats: 'Статистика чайових за період:',
    tipsTotal: 'Загальна сума чайових',
    totalCount: 'Загальна кіл-ть чайових',
    fromDate: 'Дата початку',
    dueDate: 'Дата закінчення',
    applyButton: 'Знайти',
    cancel: 'Відміна',
    receipt: 'Квитанція',
    refundReceipt: 'Квитанція з повернення',
    paymentData: 'Дані платежу',
    paymentId: 'Номер транзакції:',
    paymentRecipient: 'Отримаувач:',
    paymentDescription: 'Призначення:',
    client: 'Клієнт',
    paymentMethod: 'Спосіб:',
    paymentCard: 'Карта:',
    commission: 'Комісія:',
    statuses: 'Статуси',
    created: 'Створений:',
    card: 'Картою',
    cash: 'Готівка',
    paymentStatus: 'Статус платежу:',
    paymentRefund: 'Повернення',
    pendingBankResponse: 'Очікуємо відповідь від банку',
    paymentAmount: 'Сума:',
    receiptLoading: 'Завантаження квитанції...',
    receiptNotFound: 'Квитанцію не знайдено',
    refundReason: 'Причина повернення:',
    paymentSuccess: 'Виконано',
    refundExtraInfo:
      'Повернення коштів за вашу покупку успішно оброблено. Зарахування коштів на Ваш рахунок може зайняти до 5 днів'
  }
}
export default translationsManager
